/*-----------------------------------*\
  #main.css
\*-----------------------------------*/






/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {
  --violet-blue-crayola: hsla(234, 50%, 64%, 1);
  --dark-cornflower-blue_a7: hsla(214, 88%, 27%, 0.07);
  --white: hsla(0, 0%, 100%, 1);
  --white_a3: hsla(0, 0%, 100%, 0.03);
  --white_a8: hsla(0, 0%, 100%, 0.08);
  --white_a12: hsla(0, 0%, 100%, 0.12);
  --white_a70: hsla(0, 0%, 100%, 0.7);
  --cultured: hsla(220, 20%, 97%, 1);
  --lavender-web: hsla(233, 52%, 94%, 1);
  --cadet-blue-crayola: hsla(220, 12%, 70%, 1);
  --cadet-blue-crayola_a20: hsla(222, 23%, 71%, 0.2);
  --charcoal: hsla(218, 22%, 26%, 1);
  --raisin-black: hsla(216, 14%, 14%, 1);
  --light-gray: hsla(0, 0%, 79%, 1);
  --blue-crayola: hsla(219, 72%, 56%, 1);
  --black-coral: hsla(220, 12%, 43%, 1);

  --ff-manrope: 'Manrope', sans-serif;

  --fs-1: calc(2.7rem + 1.38vw);
  --fs-2: calc(2.6rem + .66vw);
  --fs-3: 2.2rem;
  --fs-4: 1.9rem;
  --fs-5: 1.8rem;
  --fs-6: 1.7rem;
  --fs-7: 1.5rem;
  --fs-8: 1.4rem;

  --fw-700: 700;

  --section-padding: 90px;

  --shadow-1: 0 0 20px hsla(216, 14%, 14%, 0.05);
  --shadow-2: 0 0 0 0.05rem hsla(214, 88%, 27%, 0.08), 0 0 1.25rem hsla(216, 14%, 14%, 0.06);
  --shadow-3: 0 0 1.25rem hsla(216, 14%, 14%, 0.04);

  --radius-circle: 50%;
  --radius-pill: 100px;
  --radius-10: 10px;
  --radius-8: 8px;
  --radius-6: 6px;

  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease;
  --transition-3: 0.3s ease-in-out;
  --cubic-ease-out: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
}



/*-----------------------------------*\
  #RESET
\*-----------------------------------*/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a,
img,
span,
input,
button,
ion-icon {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  height: auto;
}

input,
button {
  background: none;
  border: none;
  font: inherit;
}

input {
  width: 100%;
}

button {
  cursor: pointer;
}

ion-icon {
  pointer-events: none;
}

address {
  font-style: normal;
}

html {
  font-family: 'Montserrat';
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--white);
  color: var(--black-coral);
  font-size: 1.6rem;
  line-height: 1.7;
}

body.nav-active {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: hsl(0, 0%, 98%);
}

::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 80%);
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(0, 0%, 70%);
}

/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container {
  padding-inline: 16px;
}

.social-list {
  display: flex;
  align-items: center;
  gap: 12px;
}

.social-link {
  font-size: 2rem;
  transition: var(--transition-1);
}

.social-link:is(:hover, :focus-visible) {
  transform: translateY(-3px);
}

.section {
  padding-block: var(--section-padding);
}

.has-bg-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.h1,
.h2,
.h3,
.h4,
.h5 {
  color: var(--charcoal);
  font-weight: var(--fw-700);
  line-height: 1.3;
}

.h1 {
  font-size: var(--fs-1);
}

.h2 {
  font-size: var(--fs-2);
}

.h3 {
  font-size: var(--fs-3);
}

.h4 {
  font-size: var(--fs-4);
}

.h5 {
  font-size: var(--fs-6);
}

.btn {
  color: var(--white);
  font-size: var(--fs-6);
  font-weight: var(--fw-700);
  border: 2px solid var(--white);
  max-width: max-content;
  padding: 12px 28px;
  border-radius: var(--radius-pill);
  transition: var(--transition-1);
  will-change: transform;
}

.btn:is(:hover, :focus-visible) {
  transform: translateY(-4px);
}

.btn-primary,
.btn-outline:is(:hover, :focus-visible) {
  background-color: var(--white);
  color: var(--charcoal);
}

.img-holder {
  aspect-ratio: var(--width) / var(--height);
  background-color: var(--light-gray);
  overflow: hidden;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-subtitle {
  font-size: var(--fs-7);
  text-transform: uppercase;
  color: #002f68;
  font-weight: var(--fw-700);
  margin-block-end: 16px;
}

.grid-list {
  display: grid;
  gap: 25px;
}

.w-100 {
  width: 100%;
}

.slider {
  --slider-items: 1;
  --item-gap: 12px;

  margin-block-start: 60px;
  overflow: hidden;
}

.slider-container {
  position: relative;
  display: flex;
  gap: var(--item-gap);
  transition: transform var(--cubic-ease-out);
}

.slider-item {
  --total-gap: calc(var(--item-gap) * (var(--slider-items) - 1));
  --item-width: calc((100% / var(--slider-items)) - (var(--total-gap) / var(--slider-items)));

  min-width: var(--item-width);
  width: var(--item-width);
}

/*-----------------------------------*\
  #HEADER
\*-----------------------------------*/
.header .btn {
  display: none;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-block: 20px;
  box-shadow: var(--shadow-1);
  z-index: 4;
}

.header.active {
  background-color: var(--white);
  position: fixed;
  animation: slideIn 0.5s ease forwards;
}

@keyframes slideIn {
  0% { transform: translateY(-100%);}
  100% { transform: translateY(0);}
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.header.active .logo-light,
.header .logo-dark {
  display: none;
}

.header .logo-light,
.header.active .logo-dark {
  display: block;
}

.logo-light {
  font-size: x-large;
  font-weight: bold;
  color: var(--white);
}

.logo-dark {
  font-size: x-large;
  font-weight: bold;
  color: #002f68;
}

.navbar-top-logo {
  font-size: x-large;
  font-weight: bold;
  color: var(--white);
}

.nav-open-btn {
  font-size: 3.5rem;
  color: var(--white);  
}

.header.active .nav-open-btn {
  color: var(--charcoal);
}

.navbar {
  position: fixed;
  top: 0;
  left: -300px;
  background-color: var(--raisin-black);
  color: var(--white);
  max-width: 300px;
  width: 100%;
  height: 100vh;
  padding: 30px;
  padding-block-end: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  z-index: 2;
  visibility: hidden;
  transition: var(--transition-3);
}

.navbar.active {
  visibility: visible;
  transform: translateX(300px);
}

.navbar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-close-btn {
  background-color: var(--white_a8);
  color: var(--white);
  font-size: 2rem;
  padding: 6px;
  border-radius: var(--radius-circle);
  transition: var(--transition-1);
}

.nav-close-btn ion-icon {
  --ionicon-stroke-width: 50px;
}

.nav-close-btn:is(:hover, :focus-visible) {
  background-color: var(--white_a12);
}

.navbar-list {
  margin-block-end: auto;
}

.navbar-link {
  font-weight: var(--fw-700);
  padding-block: 6px;
}

.contact-link {
  transition: var(--transition-1);
}

.contact-link:is(:hover, :focus-visible) {
  color: var(--light-gray);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--raisin-black);
  pointer-events: none;
  opacity: 0;
  transition: var(--transition-1);
}

.overlay.active {
  pointer-events: all;
  opacity: 0.8;
}


/*-----------------------------------*\
  #HERO
\*-----------------------------------*/
.hero {
  padding-block-start: calc(var(--section-padding) + 70px);
  text-align: center;
}

.hero .container {
  display: grid;
  gap: 70px;
}

.hero-title {
  color: var(--white);
}

.hero-text {
  font-size: var(--fs-5);
  color: var(--white);
  margin-block: 24px 36px;
}

.btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px
}

.hero-slider,
.hero-card {
  position: relative;
}

.hero-card .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  color: var(--charcoal);
  font-size: 2rem;
  padding: 25px;
  border-radius: var(--radius-circle);
  transition: var(--transition-1);
  animation: pulse 2s ease infinite;
}

.hero-card .play-btn:is(:hover, :focus-visible) {
  color: #002f68;
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 var(--white_a70);}
  75% { box-shadow: 0 0 0 0 20px transparent;}
}

.hero .slider-inner {
  border-radius: var(--radius-10);
  overflow: hidden;
}

.hero .slider-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  transition: var(--transition-2);
}

.hero .slider-item {
  min-width: 100%;
  width: 100%;
  border-radius: var(--radius-10);
  overflow: hidden;
}

.hero .slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--white_a70);
  color: var(--charcoal);
  font-size: 2rem;
  padding: 12px;
  border-radius: var(--radius-circle);
  transition: var(--transition-1);
}

.hero .slider-btn:is(:hover, :focus-visible) {
  background-color: var(--white);
}

.hero .slider-btn.prev {
  left: 20px;
}

.hero .slider-btn.next {
  right: 20px;
}

/*-----------------------------------*\
  #SERVICE
\*-----------------------------------*/
.service {
  text-align: center;
}

.service .section-title {
  margin-block-end: 50px;
}

.service-card {
  padding: 40px;
  box-shadow: var(--shadow-2);
  border-radius: var(--radius-6);
}

.service-card .card-icon {
  width: 60px;
  height: 60px;
  background-color: #002f68;
  display: grid;
  place-items: center;
  color: var(--white);
  font-size: 2.5rem;
  border-radius: var(--radius-circle);
  margin-inline: auto;
}

.service-card .card-icon ion-icon {
  --ionicon-stroke-width: 50px;
}

.service-card .card-title {
  margin-block: 16px 10px;
}

.service-card .btn-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-block-start: 10px;
  color: #002f68;
  font-weight: var(--fw-700);
  transition: var(--transition-1);
}

.service-card .btn-text:is(:hover, :focus-visible) {
  opacity: 0.9;
}




/*-----------------------------------*\
  #ABOUT
\*-----------------------------------*/
.about .container {
  display: grid;
  gap: 50px;
}

.about .section-title {
  margin-block-end: 35px;
}

.accordion-card .card-title {
  padding-block-end: 20px;
}

.accordion-btn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.accordion-btn {
  display: flex;
  align-items: center;
  gap: 10px
}

.accordion-btn ion-icon {
  font-size: 1.5rem;
  color: var(--blue-crayola);
  transition: var(--transition-1);
}

.accordion-card.expanded .accordion-btn ion-icon {
  transform: rotate(0.5turn);
}

.accordion-btn .span {
  transition: var(--transition-1);
}

.accordion-btn:is(:hover, :focus-visible) .span,
.accordion-card.expanded .accordion-btn .span {
  color: #023c82;
}

.accordion-content {
  padding-inline-start: 24px;
  max-height: 0;
  overflow: hidden;
}

.accordion-card.expanded .accordion-content {
  max-height: max-content;
  padding-block-end: 20px;
}

.accordion-about-content {
  max-height: 0;
  overflow: hidden;
}

.accordion-card.expanded .accordion-about-content {
  max-height: max-content;
  padding-block-end: 20px;
}

.about-banner img {
  border-radius: var(--radius-10);
}

/*-----------------------------------*\
  #PROJECT
\*-----------------------------------*/
.project {
  background-color: var(--cultured);
}

.project :is(.section-subtitle, .section-title) {
  text-align: center;
}

.project .section-title {
  margin-block-end: 50px;
}

.project-card {
  background-color: var(--white);
  border-radius: var(--radius-8);
  overflow: hidden;
  box-shadow: var(--shadow-2);
  height: 100%;
}

.project-card .card-content {
  padding: 30px;
}

.project-card .card-title {
  color: #002f68
}

.project-card .card-text {
  margin-block: 16px 20px;
}

.project-card .card-meta-list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.project-card .card-meta-item {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #002f68;
  font-size: var(--fs-8);
}

/*-----------------------------------*\
  #TESTIMONIALS
\*-----------------------------------*/
.testimonial {
  background-color: #002f68;
}

.testimonial h2 {
  color: var(--cultured);
}

.testimonial .section-subtitle {
  color: var(--cultured);
}

.testimonial-card {
  position: relative;
  padding-inline: 36px;
  height: 100%;
  padding: 60px 40px 40px;
  transition: var(--transition-2);
}

.testimonial-card :is(.card-text, .client-name) {
  font-style: italic;
  color: var(--cultured);
  line-height: 1.3;
}

.testimonial-card .client-title {
  color: var(--white);
  opacity: 0.5;
}

.testimonial-card .card-text {
  font-size: var(--fs-5);
  margin-block-end: 40px;
}

.testimonial-card .client-name {
  font-size: var(--fs-6);
  font-weight: var(--fw-700);
}

.testimonial-card .icon {
  position: absolute;
  top: 0px;
  left: 0;
}

.slider-control {
  position: relative;
  max-width: max-content;
  padding-block: 8px;
  transition: var(--transition-1);
}

.slider-control:is(:hover) {
  opacity: 0.5;
}

.slider-control .line {
  width: 30px;
  height: 1px;
  background-color: white;
}

.slider-control .arrow {
  position: absolute;
  transform: translateY(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  border-style: solid;
  border-color: white;
}

.slider-control.prev .arrow {
  left: 0;
  border-width: 0 0 1px 1px;
}

.slider-control.next .arrow {
  right: 0;
  border-width: 1px 1px 0 0;
}
.slider-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.testimonial .slider-btn {
  position: absolute;
  /* top: 50%; */
  transform: translateY(-600%);
  background-color: var(--white_a70);
  color: var(--charcoal);
  font-size: 2rem;
  padding: 12px;
  border-radius: var(--radius-circle);
  transition: var(--transition-1);
}

.testimonial .slider-btn:is(:hover, :focus-visible) {
  background-color: var(--white);
}

.testimonial .slider-btn.prev {
  left: 50px;
}

.testimonial .slider-btn.next {
  right: 50px;
}


/*-----------------------------------*\
  #FOOTER
\*-----------------------------------*/
.footer {
  background-color: var(--raisin-black);
  padding-block:  60px 100px;
  color: var(--light-gray);
}

.footer-brand .footer-text {
  margin-block: 20px
}

.footer .social-list {
  color: var(--white)
}

.footer-list-title {
  color: var(--white);
  margin-block-end: 16px;
}

.footer-link {
  padding-block: 4px;
  transition: var(--transition-1);
}

.footer-link:is(:hover, :focus-visible) {
  color: var(--white)
}

/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/
@media (min-width: 400px) {
  .img-cover {
    max-height: 392px;
  }
}

@media (min-width: 575px) {
  .container {
    max-width: 540px;
    width: 100%;
    margin-inline: auto;
  }

  .project-card .card-content {
    padding: 40px;
  }
}

@media (min-width: 768px) {

  :root {
    --section-padding: 120px;
  }

  .container {
    max-width: 720px;
  }

  .grid-list {
    grid-template-columns: 1fr 1fr;
  }

  .header .btn {
    display: block;
    margin-inline-start: auto;
    padding: 8px 20px;
  }

  .header.active .btn {
    background-color: #002f68;
    border-color: #002f68;
    color: var(--white);
  }

  .img-cover {
    max-height: 600px;
  }

  .hero-content {
    max-width: 85%;
    margin-inline: auto;
  }

  .hero-text {
    --fs-5: 2rem;
  }

  .service .section-title {
    max-width: 30ch;
    margin-inline: auto;
  }

  .feature .container {
    grid-template-columns: 0.7fr 1fr;
    align-items: center;
  }

  .feature-banner {
    order: 1;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }

  .grid-list {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .img-cover {
    max-height: 365px;
  }

  .nav-open-btn,
  .navbar > *:not(.navbar-list),
  .overlay {
    display: none;
  }

  .navbar,
  .navbar.active{
    all: unset;
    display: block;
    margin-inline: auto 24px;
  }

  .navbar-list {
    display: flex;
    gap: 30px;
  }

  .navbar-link {
    color: var(--white);
    transition: var(--transition-1);
  }

  .navbar-link:is(:hover, :focus-visible) {
    opacity: 0.7;
  }

  .header.active .navbar-link {
    color: var(--charcoal);
  }

  .header .btn {
    margin-inline-start: 0;
  }

  .hero {
    padding-block-start: calc(var(--section-padding) + 50px);
    text-align: left;
  }

  .hero .container {
    grid-template-columns: 1fr 0.8fr;
    align-items: center;
  }

  .hero-content {
    max-width: unset;
    margin-inline: 0;
  }

  .btn-wrapper {
    justify-content: flex-start;
  }

  .project .section-title {
    max-width: 32ch;
    margin-inline: auto;
  }

  /* .footer .grid-list {
    grid-template-columns: repeat(4, 1fr);
  } */

  .slider {
    --slider-items: 2;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }

  .about .container {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  .hero-text {
    padding-inline-end: 100px;
  }

  address.footer-text {
    padding-inline-end: 100px;
    margin-block-end: 16px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }

  .feature-list {
    grid-template-columns: 1fr 1fr;
  }
}